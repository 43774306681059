import './Fragrance.css'
import * as React from 'react';
import { Modal } from '@mui/material';
import ReactGA from "react-ga4";

function Fragrance(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);

    ReactGA.event({
      category: props.name,
      action: "open-modal"
    });
  }
  const handleClose = () => {
    setOpen(false);

    ReactGA.event({
      category: props.name,
      action: "close-modal"
    });
  }

  return (
    <div className='fragrance'>
      <div className='name' onClick={handleOpen}><h2>{props.name}</h2></div>
      <div className='image' onClick={handleOpen}><img src={props.img} alt={props.alt}></img></div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={`innerModal ${props.c}`}>
          <p>{props.description}</p>
        </div>
      </Modal>
    </div>
  );
}

export default Fragrance;
