const ambientaldesDescriptionMarkup = (
    <div>
        <h3>Sahumerios y Hornillos</h3>
        <ul className='fragance-list'>
        <li>Acqua</li>
        <li>Albahaca</li>
        <li>Alcanfor</li>
        <li>Almendra</li>
        <li>Almizcles</li>
        <li>Anana</li>
        <li>Anochecer de la India <span className="exclusive">Exclusivo</span></li>
        <li>Artemisa</li>
        <li>Azahar</li>
        <li>Banana</li>
        <li>Benjuí</li>
        <li>Bergamota</li>
        <li>Café</li>
        <li>Canela</li>
        <li>Cedro Libano</li>
        <li>Champa</li>
        <li>Chanel</li> 
        <li>Cherry</li>
        <li>Chocolate</li>
        <li>Citronela</li>
        <li>Clavo de Olor</li>
        <li>Coco</li>
        <li>Copal</li>
        <li>Durazno</li>
        <li>Eucaliptus</li>
        <li>Flores de la India</li>
        <li>Fresias</li>
        <li>Frutas Tropicales</li>
        <li>Frutilla</li>
        <li>Gardenia</li>
        <li>Geranio</li>
        <li>Incienso</li>
        <li>Jazmin</li>
        <li>Jazmin Persa</li>
        <li>Jengibre</li>
        <li>Lavanda</li>
        <li>Lila</li>
        <li>Lilium</li>
        <li>Lima</li>
        <li>Limón</li>
        <li>Loto</li>
        <li>Maderas de Oriente</li>
        <li>Madreselva</li>
        <li>Magnolia</li>
        <li>Mandarina</li>
        <li>Mango</li>
        <li>Manzana Dulce</li>
        <li>Manzana Verde</li>
        <li>Manzanilla</li>
        <li>Marina</li>
        <li>Melisa</li>
        <li>Melon Miel</li>
        <li>Melón Tropico</li>
        <li>Menta</li>
        <li>Mil Flores</li>
        <li>Mirra</li>
        <li>Moras y Fresas</li>
        <li>Musk</li>
        <li>Naranja</li>
        <li>Nardo</li>
        <li>Opium</li>
        <li>Palo Santo</li>
        <li>Patchouli</li>
        <li>Pimienta</li>
        <li>Pino</li>
        <li>Pomelo Rosado</li>
        <li>Ratnamalá <span className="exclusive">Exclusivo</span></li>
        <li>Reina de la Noche</li>
        <li>Reina Diorgal</li>
        <li>Romero</li>
        <li>Rosa  Roja</li>
        <li>Rosa Bulgara</li>
        <li>Rosas Blancas</li>
        <li>Ruda</li>
        <li>Sai Baba</li>
        <li>Sai Flora</li>
        <li>Sandalo Dulce</li>
        <li>Sandalo Hindu</li>
        <li>Sandalo Mysore</li>
        <li>Sandía</li> 
        <li>Spirituals <span className="exclusive">Exclusivo</span></li>
        <li>Tabak</li>
        <li>Tea Tree</li>
        <li>Tilo</li>
        <li>Tuberosa</li>
        <li>Tutti Frutti</li>
        <li>Verdemar</li>
        <li>Vetiver</li>
        <li>Violeta</li>
        <li>Yagra</li>
        <li>Ylang</li>
        </ul>
    </div>
)

export const AMBIENTALES_MODEL = {
    img: "/sahumerios1.jpg",
    name: "Sahumerios y Hornillos",
    description: ambientaldesDescriptionMarkup,
    alt: "Esencias para Sahumerios/Hornillo",
    className: "ambientales"
}

