import './Form.css'
import { useState } from 'react';
import { send } from 'emailjs-com';
import TextareaAutosize from '@mui/base/TextareaAutosize';

function Form() {

  const [toSend, setToSend] = useState({
    from_name: '',
    from_email: '',
    from_phone: '',
    message: ''
  })

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    send(
      'service_bqtc8y4',
      'template_amnr4if',
      toSend,
      'j97c80o76-EPLDW-3'
    )
      .then((response) => {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
        console.log('FAILED...', err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value});
  };

  return (
    <div className='contact-form'>
        <h2>Contacto</h2>
        <form onSubmit={onSubmit}>
        <input
          type='text'
          name='from_name'
          placeholder='Nombre'
          value={toSend.from_name.value}
          onChange={handleChange}
        />
        <input
          type='email'
          name='from_email'
          placeholder='Email'
          value={toSend.from_email.value}
          onChange={handleChange}
        />
        <input
          type='tel'
          name='from_phone'
          placeholder='Telefono'
          value={toSend.from_phone.value}
          onChange={handleChange}
        />
        <TextareaAutosize
          minRows={3}
          name='message'
          placeholder='Dejanos tu consulta'
          value={toSend.message.value}
          onChange={handleChange}
        />
        <button type='submit'>Enviar</button>
      </form>
      <div className='form-banner success' style={{display: showSuccess ? 'inline-block' : 'none' }}>
        Tu mensaje se ha enviado exitosamente
      </div>
      <div className='form-banner error' style={{display: showError ? 'inline-block' : 'none' }}>
        Tu mensaje se ha enviado exitosamente
      </div>
    </div>
  );
}

export default Form;
