import './Fragrances.css'
import Fragrance from './Fragrance';
import { AMBIENTALES_MODEL } from './ambientales/Ambientales';
import { PERFUMERIA_MODEL } from './perfumeria/Perfumeria';
import { VELAS_MODEL } from './velas/Velas';
import { LIMPIEZA_MODEL } from './limpieza/Limpieza';


function Fragrances() {
  const ambientalesMarkup = (
    <Fragrance
        img={AMBIENTALES_MODEL.img}
        name={AMBIENTALES_MODEL.name}
        description={AMBIENTALES_MODEL.description}
        alt={AMBIENTALES_MODEL.alt}
        c={AMBIENTALES_MODEL.className}
    ></Fragrance>
  )

  const perfumeriaMarkup = (
    <Fragrance
        img={PERFUMERIA_MODEL.img}
        name={PERFUMERIA_MODEL.name}
        description={PERFUMERIA_MODEL.description}
        alt={PERFUMERIA_MODEL.alt}
        c={PERFUMERIA_MODEL.className}
    ></Fragrance>
  )

  const velasMarkup = (
    <Fragrance
        img={VELAS_MODEL.img}
        name={VELAS_MODEL.name}
        description={VELAS_MODEL.description}
        alt={VELAS_MODEL.alt}
        c={VELAS_MODEL.className}
    ></Fragrance>
  )

  const limpiezaMarkup = (
    <Fragrance
        img={LIMPIEZA_MODEL.img}
        name={LIMPIEZA_MODEL.name}
        description={LIMPIEZA_MODEL.description}
        alt={LIMPIEZA_MODEL.alt}
        c={LIMPIEZA_MODEL.className}
    ></Fragrance>
  )

  return (
    <div className='fragrances'>
      {velasMarkup}
      {ambientalesMarkup}
      {limpiezaMarkup}
      {perfumeriaMarkup}
      
    </div>
  );
}

export default Fragrances;
