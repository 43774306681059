import './Header.css'

function Header() {
  return (
    <div className='header'>
      <div className='logo'><img src="/delipar_logo.jpg" alt="logo" /></div>
      <div></div>
    </div>
  );
}

export default Header;
