import * as React from 'react';
import Cover from "./cover/Cover";
import Header from "./header/Header";
import Fragrances from "./fragrances/Fragrances";
import Footer from "./footer/Footer";
import Whatsapp from "./contact/Whatsapp";
import Form from "./contact/Form";
import ReactGA from "react-ga4";
import { Snackbar } from "@mui/material";
import Slide from '@mui/material/Slide';
import './App.css'

function App() {
  const [open, setOpen] = React.useState(false);
  setTimeout(() => setOpen(true), 5000);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const TRACKING_ID = "G-7452BVCMXP"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  return (
    <div style={{fontFamily: 'sans-serif', boxSizing: 'content-box'}}>
      <link rel="preload" as="image" href="/cover_1.jpeg"></link>
      <Header></Header>
      <Cover></Cover>
      <Fragrances></Fragrances>
      <Form></Form>
      <Footer></Footer>
      <Whatsapp></Whatsapp>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={Slide.SlideTransition}
        autoHideDuration={8000}
      >
        <div className="snackbar-custom">
          Venta minima por esencia 0,250 kg
        </div>
      </Snackbar>
    </div>
  );
}

export default App;
