import './Cover.css'
import { register } from 'swiper/element/bundle';
import Swiper, { Navigation, Autoplay } from 'swiper';



function Cover() {
  register();
  const textMarkup = (
    <div>
      <h1>Bienvenidos a Delypar</h1>
      <p>Los aromas tienen la virtud de cambiar nuestros estados de ánimo y trasladarnos hasta lugares lejanos en el tiempo y espacio.</p>
      <p>Somos una empresa familiar con mas de 30 años de permanencia en el mercado.</p>
      <p>Formulamos nuestras propias fragancias partiendo de esencias naturales y sintéticas. Disponemos de más de setecientas materias primas, en su mayoría importadas.</p>
      <h2>Cómo trabajamos</h2>
      <p>Fraccionamos y comercializamos nuestras esencias en forma directa desde un mínimo de 0.250 kg., facilitando así a los emprendedores a disponer de una amplia gama de notas sin recurrir a una importante inversión desde el comienzo.</p>
    </div>
  )

  const swiper = new Swiper('.swiper', {
    modules: [Navigation, Autoplay],
    autoplay: {
      delay: 1000,
    },
   });

  return (
    <div className='cover'>
      <div className='container'>
        <div className='image'>
        <swiper-container className="swiper" navigation="true" autoplay="true">
          <swiper-slide>
            <div className='carrousel-container c1'></div>
          </swiper-slide>
          <swiper-slide>
            <div className='carrousel-container c2'></div>
          </swiper-slide>
          <swiper-slide>
            <div className='carrousel-container c3'></div>
          </swiper-slide>
          <swiper-slide>
            <div className='carrousel-container c4'></div>
          </swiper-slide>
        </swiper-container>

        </div>
        <div className='text'>
          {textMarkup}
        </div>
      </div>
    </div>
  );
}

export default Cover;
