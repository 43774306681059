const velasDescriptionMarkup = (
  <div>
    <h3>Esencias para Velas</h3>
    <ul className='fragance-list'>
      <li>Acqua</li>
      <li>Albahaca</li>
      <li>Albahaca-Limón</li>
      <li>Almendra</li>
      <li>Ananá</li>
      <li>Anís</li>
      <li>Arándanos y Frutos del Sur</li>
      <li>Azahar</li>
      <li>Azahar-Te Verde</li>
      <li>Bergamota</li>
      <li>Café</li>
      <li>Canela</li>
      <li>Canela-Manzana</li>
      <li>Canela-Naranja</li>
      <li>Canela-Naranja-Jengibre</li>
      <li>Caramel</li>
      <li>Césped</li>
      <li>Cilantro-Pimienta</li>
      <li>Cítrico</li>
      <li>Citronela</li>
      <li>Clerico de Verano</li>
      <li>Coco</li>
      <li>Coco-Verbena</li>
      <li>Coconut</li>
      <li>Coco-Vainilla</li>
      <li>Colonia</li>
      <li>Durazno</li>
      <li>Durazno-Fresias</li>
      <li>Especy</li>
      <li>Eucaliptus</li>
      <li>Explosión Floral</li>
      <li>Fleur´s</li>
      <li>Floral</li>
      <li>Flores Blancas</li>
      <li>Fresias</li>
      <li>Frutas Tropicales</li>
      <li>Frutos Rojos</li>
      <li>Gardenia</li>
      <li>Geranio 1805</li>
      <li>Henno</li>
      <li>Herbal</li>
      <li>Hierbas</li>
      <li>Higo</li>
      <li>Jazmín</li>
      <li>Jengibre</li>
      <li>Lavanda</li>
      <li>Lemongrass</li>
      <li>Lilium</li>
      <li>Lilium-Tilo</li>
      <li>Lilium-Bergamota</li>
      <li>Lima</li>
      <li>Limón</li>
      <li>Loto</li>
      <li>Maderas & Especias</li>
      <li>Maderas-Café</li>
      <li>Madreselva</li>
      <li>Magnolia</li>
      <li>Mandarina y Frutos Rojos</li>
      <li>Mango</li>
      <li>Marina</li>
      <li>Mediterráneo</li>
      <li>Melisa</li>
      <li>Melón Miel</li>
      <li>Melón Trópico</li>
      <li>Menta</li>
      <li>Miel</li>
      <li>Moras y Fresas</li>
      <li>Naranja Dulce</li>
      <li>Nardo</li>
      <li>Orquídea</li>
      <li>Palo Santo Fantasy</li>
      <li>Palta</li>
      <li>Patchouli "R"</li>
      <li>Peonias</li>
      <li>Peonias y Rosas</li>
      <li>Pepino</li>
      <li>Pera Glacé</li>
      <li>Pera y Flores Blancas</li>
      <li>Pino de los Andes</li>
      <li>Pino Spruce</li>
      <li>Pomelo Rosado</li>
      <li>Pomelo-Melón</li>
      <li>Provence</li>
      <li>Rocío de Seda</li>
      <li>Romero</li>
      <li>Rosa Búlgara</li>
      <li>Rosa Roja</li>
      <li>Rosas Blancas</li>
      <li>Rosas y Jazmines</li>
      <li>Ruda</li>
      <li>Salvia</li>
      <li>Sándalo-Coco</li>
      <li>Sándalo Dulce</li>
      <li>Sándalo-Lavanda</li>
      <li>Sándalo Maderas</li>
      <li>Tabak</li>
      <li>Te Verde</li>
      <li>Tilo</li>
      <li>Tilo-Lavanda</li>
      <li>Tilo-Verbena</li>
      <li>Tomillo</li>
      <li>Tuberosa y Jazmines</li>
      <li>Vainicoco</li>
      <li>Vainilla</li>
      <li>Verbena</li>
      <li>Verde Pradera</li>
      <li>Verdemar</li>
      <li>Yerba Mate</li>
      <li>Ylang Ylang</li>
      <li>Yuyo del Cerro</li>
    </ul>
  </div>
  )

export const VELAS_MODEL = {
    img: "/velas1.jpg",
    name: "Esencias para Velas",
    description: velasDescriptionMarkup,
    alt: "Esencias para Velas",
    className: "velas"
}

