const limpiezaDescriptionMarkup = (
  <div>
    <h3>Esencias para productos Limpieza/Ambientales</h3>
    <ul className='fragance-list'>
      <li>A-Verde</li> 
      <li>Algas</li>
      <li>Acqua</li> 
      <li>Arpege</li>
      <li>Brisas</li>
      <li>Bruma</li>
      <li>Camel</li>
      <li>Campos Verdes</li>
      <li>Cherry</li>
      <li>Cítrico</li>
      <li>Citronela</li>
      <li>Coni</li> 
      <li>Coco 2201</li>
      <li>Coco Hawai</li>
      <li>Coco Vainilla</li>
      <li>Colonia Inglesa</li>
      <li>Colonia BB</li>
      <li>Confort</li>
      <li>Drakar</li>
      <li>Durazno</li>
      <li>Feria Primaveral</li>
      <li>Floral</li>
      <li>Flores Blancas</li>
      <li>Floridita</li>
      <li>Flow</li>
      <li>Fragancia BB</li>
      <li>Frambuesa</li>
      <li>Fresias</li>
      <li>Frutas Tropicales</li>
      <li>Frutilla 1989</li>
      <li>Frutilla 2435</li>
      <li>Frutilla Frambuesa</li>
      <li>Gardenia</li>
      <li>Hierbas</li> 
      <li>Higo</li> 
      <li>Jazmín</li>
      <li>Karen</li>
      <li>Lago Azul</li>
      <li>Lavanda Economica</li>
      <li>Lavanda TF</li>
      <li>Lavanda Dulce</li>
      <li>Lilium</li>
      <li>Limón Caramelito</li>
      <li>Limón Dulce</li>
      <li>Limón Verde</li>
      <li>Love</li>
      <li>Mango</li> 
      <li>Mandarina</li>
      <li>Manzana Dulce</li>
      <li>Manzana Verde</li>
      <li>Marina</li>
      <li>Melón Miel</li>
      <li>Melón Trópico</li>
      <li>Moras y Fresas</li>
      <li>Mburucuyá</li>
      <li>Naranja Dulce</li>
      <li>Naranja Durazno</li>
      <li>Niko</li> 
      <li>Orquídea</li>
      <li>Papaya</li>
      <li>Patio B</li>
      <li>Pepino</li>
      <li>Pera Glacé</li>
      <li>Pera y Flores Blancas</li>
      <li>Perfumina Textil</li>
      <li>Pino Fresh</li>
      <li>Pomelo Rosado</li>
      <li>Provence</li>
      <li>Rocío de Seda</li>
      <li>Rosa  Roja</li>
      <li>Sandía Melón</li>
      <li>Softy</li>
      <li>Spring</li>
      <li>Suavidad de Algodón</li>
      <li>Suavex</li>
      <li>Té Verde</li>
      <li>Tiare</li>
      <li>Tilo  Verde</li>
      <li>Tilo-Lavanda</li>
      <li>Tutti Frutti</li>
      <li>Uva Dulce</li>
      <li>Vainilla</li>
      <li>Verbena</li>
      <li>Verdemar</li>
      <li>Wanama</li>
    </ul>
</div>
  )

export const LIMPIEZA_MODEL = {
    img: "/limpieza8.jpg",
    name: "Esencias para productos Limpieza/Ambientales",
    description: limpiezaDescriptionMarkup,
    alt: "Esencias para productos Limpieza/Ambientales",
    className: "limpieza"
}

