import './Footer.css'

function Footer() {
  return (
    <div className='footer'>
      <div className='footerInfo'>
      <h2>Delypar</h2>
        <p>Andrés Lamas 2044/46</p>
        <p>(C1416BIR), C.A.B.A. Argentina</p>
        <p>Teléfono: 4583-0266</p>
        <p>delypar@delyparesencias.com.ar</p>
      </div>
      <div className='footerLinks'>
        <div>
          <a href="https://servicios1.afip.gov.ar/clavefiscal/qr/mobilePublicInfo.aspx?req=e1ttZXRob2Q9Z2V0UHVibGljSW5mb11bcGVyc29uYT0yNzAyNzYzNjk4MF1bdGlwb2RvbWljaWxpbz0wXVtzZWN1ZW5jaWE9MF1bdXJsPWh0dHA6Ly93d3cuZGVseXBhcmVzZW5jaWFzLmNvbS5hcl19" target="_F960AFIPInfo">
            <img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" width="60" height="82" border="0" alt="afip" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
