const perfumeriaDescriptionMarkup = (
    <div>
      <h3>Perfumeria Fina</h3>
      <p>Consúltenos sobre fragancias para Perfumería fina según tendencias internacionales.</p>
    </div>
  )

export const PERFUMERIA_MODEL = {
    img: "/fina2_web.jpg",
    name: "Perfumeria Fina",
    description: perfumeriaDescriptionMarkup,
    alt: "Perfumeria Fina",
    className: "perfumeria"
}

